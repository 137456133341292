export default {
  translation: {
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    previous: 'Précédent',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
    validate: 'Valider',
    phone: 'Téléphone',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
    token_error: "Votre lien à expiré ou n'est plus valide",
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Réinitialisation du mot de passe',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez votre référent chez Hellio.<br>",
  },
  homepage: {
    hero: 'Accueil',
  },
  ajout_dossier: {
    hero: 'Soumission de dossier',
    success_0: 'Soumission d’un dossier',
    success_1:
      'Votre demande a bien été prise en compte.\nNous vous recontacterons prochainement.',
    home: 'Revenir à l’accueil',
  },
  add_dossier_site: {
    two_years: 'Site existant depuis plus de  2 ans',
    first_power: 'Énergie de chauffage principale',
    electricity: 'Électricité',
    combustible: 'Combustible',
    no_pertinence: 'Non pertinent',
    alert:
      "Dans le cas où le site n'est ni un site ou tertiaire, sélectionner “ Non-pertinent ”",
    alert_2:
      'Si vous n’avez pas d’ordre d’idée de la date d’engagement prévisionnelle, indiquer une date correspondant à la date actuelle + 15 jours',
    surface: 'Surface totale chauffée de la partie réhabilitée',
    start_work: "Date d'engagement prévisionnelle des travaux",
    end_date: 'Date de fin prévisionnelle des travaux',
    reference_lastname: 'Nom du référent commercial',
    reference_firstname: 'Prénom du référent commercial',
    reference_email: 'Email du référent commercial',
    beneficiary_social_reason: 'Raison sociale du bénéficiaire',
    site_name: 'Nom du site chantier',
    site_address: 'Adresse du chantier',
    site_postal_code: 'Code postal du chantier',
    site_city: 'Ville du chantier',
  },
  add_dossier_project: {
    two_years: 'Bâtiment existant depuis plus de 2 ans',
    nature: 'Nature des travaux',
    usage: 'Usage du site chantier',
    operation: "Type d'opération",
    ventilation_surface:
      'Surface totale ventilée de la partie réhabilitée (m²)',
    chauffage_previews_energy: 'Ancienne énergie de chauffage du bâtiment:',
    chauffage_new_energy: 'Nouvelle énergie de chauffage du bâtiment:',
    chauffage_surface_building: 'Surface totale chauffée du bâtiment (m2)',
    chauffage_surface_rehabilitation:
      'Surface totale chauffée de la partie réhabilitée (m2)',
  },
  add_dossier_beneficiary: {
    Salutation: 'Civilité',
    Firstname: 'Nom du contact bénéficiaire',
    Lastname: 'Prénom du contact bénéficiaire',
    Email: 'Adresse e-mail du contact bénéficiaire',
    Phone: 'Téléphone du contact bénéficiaire',
    aif_model: "Modèle d'incitation financière souhaité",
    own_company: "L'entreprise de travaux est votre société",
    Description: 'Décrivez-nous votre projet :',
    files: 'Déposez vos fichiers ici',
    files_description:
      'Vous pouvez ajouter jusqu’à 10 fichiers simultanément.\nSi vous voulez ajouter plus de 10 fichiers, effectuez le dépôt en plusieurs fois.',
    aif_alert:
      'AIF : Accord d’incitation financière (contrat)\n' +
      'Remise sur devis : Prime intégrée sur le devis sous forme de remise',
    Nom_ETx__c: "Raison sociale de l'entreprise",
    Adresse_de_l_Entreprise__c: "Adresse de l'entreprise",
    Code_Postal_de_l_Entreprise__c: "Code postal de l'entreprise",
    Ville_ETx__c: "Ville de l'entreprise",
    T_l_phone_de_l_entreprise__c: "Téléphone de l'entreprise",
    Email_de_l_entreprise__c: "E-mail de l'entreprise",
    policies_0:
      "J'accepte d'être recontacté par Hellio ou l'un de ses partenaires.\nPour plus d'informations, je peux consulter la ",
    policies_1: 'Politique de confidentialité de Hellio.',
  },
  dossiers: {
    hero: 'Suivi de dossier',
    filter: 'Filtres',
    referent: 'Référent commercial',
    keyword: 'Référence chantier, adresse des travaux...',
    filter_steps: 'Filtrer par étapes',
    filter_referent: 'Filtrer par référent commercial',
    export: 'Exporter les données (.csv)',
    reset: 'Réinitialisation',
  },
  dossier_table: {
    no_data: 'Aucune donnée disponible',
    PM_Societe_Nom__c: 'Raison sociale du bénéficiaire',
    CHA_Nom_du_site__c: 'Nom du site chantier',
    CHA_Adresse_du_site__c: 'Adresse des travaux',
    LastModifiedDate: 'Date de mise à jour',
    PRE_date_fin__c: 'Date de fin des travaux',
    Name: 'Référence chantier',
    Montant_Prime_Ben__c: 'Montant des primes (€)',
    Statut_cotation_community__c: 'Statut',
    BTP_interlocuteur_technique_chantier__c: 'Référent commercial',
    Type_d_operation_GC__c: 'Opération',
    Fiche_d_operation_GC__c: 'Fiche d’opération standardisée',
    PRECA_Total_Prime_Operation__c: 'Montant prime d’opération (€)',
    Date_fin_travaux__c: 'Date de fin des travaux',
    details: 'Voir détail',
    user: "Raison sociale de l'installateur",
  },
  faq: {
    title: 'FAQ',
    hero_0: 'Foire aux',
    hero_1: 'questions',
  },

  news: {
    title: 'Actualités',
    read: "Lire l'article",
    loading: 'En cours de chargement. Veuillez patienter...',
    error:
      "Une erreur c'est produite lors du chargement des articles. Veuillez réessayer plus tard!",
  },
  contact: {
    title: 'Votre référent Hellio',
    no_contact: 'Aucun référent commercial n’est rattacher à votre compte',
  },
};
