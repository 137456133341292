import BaseService from 'services/api/BaseService';
import { AuthUrls, UserUrls } from 'services/api/urls';

class AuthService {
  static get_userInfo = () => {
    return BaseService.getRequest(UserUrls.GET_CONNECTED_INFO, true);
  };

  static password_login = (info: any) => {
    return BaseService.postRequest(AuthUrls.PASSWORD_LOGIN, info, false);
  };

  static reset_password_init = (email: string) => {
    return BaseService.postRequest(
      AuthUrls.RESET_PASSWORD_INIT,
      { email },
      false,
    );
  };

  static reset_password = (info: any) => {
    return BaseService.postRequest(AuthUrls.RESET_PASSWORD, info, false);
  };

  static create_password = (info: any) => {
    return BaseService.postRequest(AuthUrls.CREATE_PASSWORD, info, false);
  };

  static impersonate = (token: string) => {
    return BaseService.postRequest(AuthUrls.IMPERSONATE, { token }, false);
  };
}

export default AuthService;
